<template>
  <v-navigation-drawer
    v-if="false" 
    v-model="getThemeMode.verticalSaasSidebarDrawer"
    :mobile-breakpoint="960"
    height="100vh"
    class="vertical-sass-sidebar -mt-8"
    :right="$vuetify.rtl"
    color="#F2F3F8"
    flat
    floating
    app
    clipped  
  >
    <vue-perfect-scrollbar
      :settings="{ suppressScrollX: true, wheelPropagation: false }"
      class="h-100 rtl-ps-none ps scroll"
      style="height: 100%"
    >
      <v-toolbar-title class="mt-2 ml-5 d-md-none d-inline-block" > <v-img class="d-inline-block" src="@/assets/images/SmallLogoMassageAppLightGray.png" style="vertical-align: middle;" width="70" /><span class="d-inline-block ml-2"  style="line-height: 95%; vertical-align: middle;">Massage<br/>App</span></v-toolbar-title>
      <v-list rounded dense>
        <template v-for="(item, i) in computedItems">
          <base-item-group
            v-if="item.children"
            :key="`group-${i}`"
            :item="item"
            aria-expanded="true"
          >
            <!--  -->
          </base-item-group>

          <base-item v-else :key="`item-${i}`" :item="item" />
        </template>
      </v-list>
    </vue-perfect-scrollbar>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { items } from "../../data/navigation";

export default {
  // components: {
  //   VuePerfectScrollbar,
  // },

  data() {
    return {
      background: true,
      items: items,
      opacity: 0.4,
    };
  },
  computed: {
    bg() {
      return this.background
        ? "https://images.pexels.com/photos/1687678/pexels-photo-1687678.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260"
        : undefined;
    },

    computedItems() {
      return this.items.map(this.mapItem);
    },

    ...mapGetters(["getThemeMode"]),

    showMenu() {
      let ttt = getThemeMode.verticalSaasSidebarDrawer;
      let userData = localStorage.getItem("userInfo");
      let enableSidebarDrawer = false;
      if(userData) {
        if(JSON.parse(localStorage.getItem("userInfo")).user.role !== "customer") {
          enableSidebarDrawer = true
        }
      }

      return ttt && enableSidebarDrawer;
    }
  },
  methods: {
    mapItem(item) {
      return {
        ...item,
        children: item.children ? item.children.map(this.mapItem) : undefined,
        title: item.title,
      };
    },
  },
};
</script>

<style>
.ps-container {
  position: inherit !important;
}

@media screen and (max-width: 1024px) {
  /* .vertical-sass-sidebar {
    position: fixed;
    left: -251px;
  } */
}
</style>
